<script>
import axios from 'axios';

export default {
  data() {
    return {
      isInvalidTitle: false,
      isInvalidShort: false,
      isInvalidMemo: false,
      showAlert: false,

      title: '',
      short: '',
      memo: ''
    }
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {
        }
      }
    }
  },
  components: {
  },
  methods: {
    getField(key) {
      if(this.data && this.data.podcast) {
        return this.data.podcast[key];
      }
      return '';
    },
    backToPodcast: function() {
      this.$router.push('/podcast/' + this.$route.params.name);
    },
    sendDetailsForm: function() {
      var r = this.validateForm();
      if(r) {
          var data = {
              title: this.title,
              short: this.short,
              memo: this.memo,
          };

          var vm = this;
          axios.post('/api/management/episode/create/' + this.$route.params.name, data, { withCredentials: true }).then(function (e) {
            vm.$router.push('/episode/' + e.data.episode.id);
          });
      }
    },
    validateForm: function () {
        this.isInvalidTitle = !this.title;
        this.isInvalidShort = !this.short;
        this.isInvalidMemo = !this.memo;

        if(!(this.isInvalidTitle || this.isInvalidMemo || this.isInvalidShort))
            return true;
        
        return false;
    },
  },
};
</script>

<template>
  <b-alert :show="showAlert" variant="success" dismissible>
      <strong>Success!</strong> Form was saved
  </b-alert>

  <form v-on:submit.prevent="sendDetailsForm" class="needs-validation">
    <div class="row">

      <div class="col-lg-12">
        <div class="mb-3">
          <label for="titleNameInput" class="form-label">Title</label>
          <input type="text" class="form-control" id="titleNameInput" placeholder="Enter your episode title" v-model="title" v-bind:class="{ 'is-invalid': isInvalidTitle }">
          <div class="invalid-feedback">Please enter episode title.</div>
        </div>
      </div>

      <div class="col-lg-6">
        <div class="mb-3">
          <label for="shortInput" class="form-label">Short</label>
          <input type="text" class="form-control" id="shortInput" placeholder="Short description" v-model="short" v-bind:class="{ 'is-invalid': isInvalidShort }">
        </div>
      </div>
      <div class="col-lg-6">
        <div class="mb-3">
        </div>
      </div>
      
      <div class="col-lg-12">
        <div class="mb-3 pb-2">
          <label for="descriptionInput" class="form-label">Description</label>
          <textarea class="form-control" id="descriptionInput" placeholder="Enter your description" rows="10" v-model="memo" v-bind:class="{ 'is-invalid': isInvalidMemo }"></textarea>
        </div>
      </div>
      <div class="col-lg-12">
        <div class="hstack gap-2 justify-content-end">
          <button type="submit" class="btn btn-primary">Create</button>
          <button type="button" class="btn btn-light" v-on:click="backToPodcast">Cancel</button>
        </div>
      </div>
    </div>
  </form>
</template>